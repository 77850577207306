export const contacts = [
  {
    icon: 'akar-icons:linkedin-fill',
    link: 'https://www.linkedin.com/in/collin-pfeifer/',
    user: 'Collin Pfeifer',
    description: 'This is where I network and build my professional portfolio',
  },
  {
    icon: 'akar-icons:twitter-fill',
    link: 'https://twitter.com/pfeifer_collin',
    user: 'pfeifer_collin',
    description: 'This is where I post whatever is on my mind',
  },
  {
    icon: 'akar-icons:github-fill',
    link: 'https://github.com/collinpfeifer',
    user: 'collinpfeifer',
    description:
      'This is where I display my code for personal or work purposes',
  },
  {
    icon: 'fluent:document-one-page-24-filled',
    link: './collin-pfeifer-resume.pdf',
    user: 'collinpfeifer.pdf',
    description:
      'My resumé made with Vue.js and LESS',
  },
];
